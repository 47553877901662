.App,
.Portfolio {
  background-color:	#78866b; 
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.NamePlate {
  /* TODO: add min-width, min-height */
  font-size: calc(10px + 3vmin);
  width: 50vmin;
  height: 5vmin;
  border: solid 3px #3e4d34;
  border-radius: 15px;
  background-color: #dbead2;
  /* color: #3e4d34; */
}

.Portfolio a {
  color: black;
  text-decoration: None;
}

.Entry {
  font-size: calc(10px + 1vmin);
  width: 50vmin;
  min-height: 5vmin;
  border: solid 3px #3e4d34;
  border-radius: 15px;
  background-color: #dbead2;
  margin: 5px 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.blurb {
  min-width: 50vmin;
  max-width: 90vmin;
  font-weight: bold;
  font-size: 21px;

  /* text-align: center; */
  
}

.projectsSection {
  display: flex;
  flex-direction: row;
}

.pastProjects,
.currentProjects {
  border: solid 4px #3e4d34;
  border-radius: 15px;
  background-color: #dbead2;
  margin: 20px;
  width: 60vmin;
  max-width: 40vw;
  min-height: 30vmin;
  /* flex-direction: column; */
}

.projectsSection ul {
  display: flex;
  /* flex-direction: column; */
  list-style-type: None;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.projectsSection li {
  margin: 10px;
  background-color: pink;
  border: solid 2px red;
}

/* .project {
  width: 50px;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
